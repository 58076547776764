import CatalogImage from 'components/MediaServerImage/CatalogImage';
import Dropdown from 'components/Dropdown/LiveDropdown';
import H4 from 'primitives/Typography/Headings/H4';
import InfiniteScroll from 'react-infinite-scroller';
import LiveDescription from 'components/LiveDescription';
import LiveDirectoryHead from 'views/Live/LiveDirectory/LiveDirectoryHead';
import LiveSelectBox from './primitives/LiveSelectBox';
import LiveSelectBoxes from './primitives/LiveSelectBoxes';
import Loader from 'components/Loader';
import NavLink from 'components/NavLink';
import PageBody, { ViewName } from 'views/PageBody';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import Section from 'components/Section';
import SelectBox from 'web-ui/templates/FormElements/SelectBox';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import transport from 'api/transport';
import { buildLiveDirectoryUrl } from 'views/Live/LiveDirectory/LiveDirectoryHead/helpers';
import { Component } from 'react';
import { Country, Genre, LiveStation } from 'state/Live/types';
import { get, isEmpty, throttle } from 'lodash-es';
import { getCountryOptions } from 'state/Location/services';
import { getLiveMarketUrl, getMarketName } from 'state/Live/helpers';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { Market } from 'state/Location/types';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

type Props = {
  ampUrl: string;
  countryOptions: Array<Country>;
  currentCountry: Country;
  currentGenre: Genre;
  currentMarket: Market;
  currentPath: string;
  genreOptions: Array<Genre>;
  geoMarket: Market;
  marketOptions: Array<Market>;
  replaceHistoryState: (path: string) => void;
  setCountry: (country: Country) => Promise<void>;
  setCountryOptions: (countries: Array<Country>) => Promise<void>;
  setGenre: (genre: Genre | null) => Promise<void>;
  setHasHero: (hasHero: boolean) => void;
  setMarket: (market: Market) => Promise<void>;
  stations: Array<LiveStation>;
  supportsSocialConnect: boolean;
  translate: IGetTranslateFunctionResponse;
};

const pageSize = 6;

type State = {
  offset: number;
};

class LiveDirectory extends Component<Props, State> {
  state = {
    offset: 6,
  };

  componentDidMount() {
    this.props.setHasHero(false);

    this.redirectToGeoMarket();
  }

  componentDidUpdate() {
    this.redirectToGeoMarket();
  }

  getDefaultGenreOption() {
    return {
      title: this.props.translate('All Genres'),
      value: null,
    };
  }

  getDefaultMarketOption() {
    return {
      title: this.props.translate('All Cities'),
      value: null,
    };
  }

  async redirectToGeoMarket() {
    const {
      currentGenre,
      currentPath,
      geoMarket,
      replaceHistoryState,
      setCountry,
      setMarket,
      setCountryOptions,
      ampUrl,
    } = this.props;
    if (currentPath === '/live/' && geoMarket) {
      const {
        marketId,
        city,
        stateAbbreviation,
        countryAbbreviation,
        countryName,
        countryId,
      } = geoMarket;

      const countryResponse = await transport(getCountryOptions(ampUrl));
      setCountryOptions(countryResponse?.data?.hits ?? []);

      await setCountry({
        abbreviation: countryAbbreviation,
        id: countryId,
        name: countryName,
      });
      setMarket(geoMarket);

      const location = buildLiveDirectoryUrl(
        countryAbbreviation,
        marketId,
        city,
        stateAbbreviation,
        currentGenre ? currentGenre.id : null,
      );
      replaceHistoryState(location);
    }
  }

  resetStations = () => {
    this.setState({
      offset: 6,
    });
  };

  updateMarket = (market: Market) => {
    const { currentCountry, currentGenre, replaceHistoryState, setMarket } =
      this.props;
    const path = getLiveMarketUrl(market, currentCountry, currentGenre?.id);
    setMarket(market);
    replaceHistoryState(path);
    this.resetStations();
  };

  updateCountry = (country: Country) => {
    this.updateGenre(null);
    this.props.setCountry(country);
    const path = `/live/country/${country.abbreviation}/`;
    this.props.replaceHistoryState(path);
    this.resetStations();
  };

  updateGenre = (genre: Genre | null) => {
    const { currentPath, replaceHistoryState, setGenre } = this.props;
    setGenre(genre);
    const [pathname] = currentPath.split('?genreId');
    const url = `${pathname}${genre?.id ? `?genreId=${genre.id}` : ''}`;
    replaceHistoryState(url);
    this.resetStations();
  };

  renderSectionTitle() {
    const { currentMarket, currentGenre, currentCountry, stations, translate } =
      this.props;

    const genreFilterDescription =
      currentGenre && Object.keys(currentGenre).length ?
        translate('for {currentGenre}', {
          currentGenre: <strong>{get(currentGenre, 'name')}</strong>,
        })
      : '';

    const marketFilterDescription =
      currentMarket ?
        translate('in {currentMarket}', {
          currentMarket: (
            <strong>
              {getMarketName(
                (Object.keys(currentMarket).length ?
                  currentMarket
                : null) as Market,
                currentCountry,
              )}
            </strong>
          ),
        })
      : '';

    const noFiltersMessage =
      currentGenre && !Object.keys(currentGenre).length && !currentMarket ?
        <span>{translate('near you')}</span>
      : '';

    return (
      <H4 as="h2" style={{ fontWeight: 'normal' }}>
        {translate(
          'Find top stations {genreFilterDescription} {marketFilterDescription} {noFiltersMessage} ({numStations})',
          {
            genreFilterDescription,
            marketFilterDescription,
            noFiltersMessage,
            numStations: stations ? stations.length : 'loading',
          },
        )}
      </H4>
    );
  }

  loadMoreStations = () => {
    const { offset } = this.state;
    const { stations } = this.props;

    let newOffset = offset + pageSize;
    if (newOffset > stations.length) {
      newOffset = stations.length;
    }

    this.setState({ offset: newOffset });
  };

  buildRenderValues() {
    const {
      countryOptions = [],
      marketOptions = [],
      genreOptions = [],
      currentCountry,
      currentMarket,
      currentGenre,
      translate,
      currentPath,
      supportsSocialConnect,
      stations,
    } = this.props;

    const { offset } = this.state;

    const currentCountryOption = {
      title: get(currentCountry, 'name'),
      value: currentCountry,
    };
    const currentMarketOption =
      currentMarket ?
        {
          title: getMarketName(currentMarket),
          value: currentMarket,
        }
      : this.getDefaultMarketOption();

    const currentGenreOption =
      currentGenre ?
        {
          title: get(currentGenre, 'name'),
          value: currentGenre,
        }
      : this.getDefaultGenreOption();

    let displayableCountryOptions = [{ title: '', value: {} }];

    if (countryOptions.length) {
      displayableCountryOptions = countryOptions.map<{
        title: string;
        value: Country;
      }>((country: Country) => ({
        title: get(country, 'name'),
        value: country,
      }));
    }

    return {
      currentCountry,
      currentCountryOption,
      currentGenre,
      currentGenreOption,
      currentMarket,
      currentMarketOption,
      currentPath,
      displayableCountryOptions,
      genreOptions,
      marketOptions,
      offset,
      stationList: stations,
      supportsSocialConnect,
      translate,
    };
  }

  render() {
    const {
      currentCountry,
      currentGenre,
      currentMarket,
      currentPath,
      currentCountryOption,
      currentMarketOption,
      currentGenreOption,
      displayableCountryOptions,
      genreOptions,
      marketOptions,
      offset,
      supportsSocialConnect,
      stationList,
      translate,
    } = this.buildRenderValues();

    const social = {
      supportsConnect: supportsSocialConnect,
      url: currentPath,
    };

    return (
      <>
        <LiveDirectoryHead
          country={currentCountry}
          genre={currentGenre}
          market={currentMarket}
        />
        <PageBody
          dataTest={ViewName.LiveDirectory}
          social={social}
          title={translate('Listen to Live Radio')}
        >
          <Section
            appendEmpty
            dataTest="live-directory-section-header"
            emptyMessage={translate(
              'No stations currently available for this filter',
            )}
            header={this.renderSectionTitle()}
            isEmpty={isEmpty(stationList)}
          >
            <LiveSelectBoxes>
              <LiveSelectBox data-test="live-select-country" right>
                <SelectBox
                  dataTest="countryDropdown"
                  name="country"
                  onChange={this.updateCountry}
                  options={displayableCountryOptions}
                  selectClasses="short"
                  selectedOption={currentCountryOption}
                  tabindex={1}
                />
              </LiveSelectBox>
              <LiveSelectBox data-test="live-select-city">
                <SelectBox
                  dataTest="cityDropdown"
                  name="city"
                  onChange={this.updateMarket}
                  options={[
                    this.getDefaultMarketOption(),
                    ...marketOptions.map(market => ({
                      title: getMarketName(market),
                      value: market,
                    })),
                  ]}
                  selectClasses="short"
                  selectedOption={currentMarketOption}
                  tabindex={3}
                />
              </LiveSelectBox>
              <LiveSelectBox data-test="live-select-genre" left>
                <SelectBox
                  dataTest="genreDropdown"
                  name="genre"
                  onChange={this.updateGenre}
                  options={[
                    this.getDefaultGenreOption(),
                    ...genreOptions.map(genre => ({
                      title: get(genre, 'name'),
                      value: genre,
                    })),
                  ]}
                  selectClasses="short"
                  selectedOption={currentGenreOption}
                  tabindex={2}
                />
              </LiveSelectBox>
            </LiveSelectBoxes>
            {isEmpty(stationList) ? null : (
              <InfiniteScroll
                hasMore={offset < stationList.length}
                initialLoad
                loader={<Loader key="live-directory-loader" />}
                loadMore={throttle(this.loadMoreStations, 1000)}
                threshold={0}
              >
                <Tiles data-test="station-tile-section" tilesInRow={3}>
                  {stationList
                    .slice(0, offset)
                    .map(
                      ({
                        description,
                        imgWidth,
                        name,
                        seedId,
                        seedType,
                        url,
                        logo,
                        rawLogo,
                        playedFrom,
                      }) => (
                        <Tile
                          dropdown={
                            <Dropdown
                              key={`station-${seedId}`}
                              name={name}
                              seedId={seedId}
                              seedType={seedType}
                            />
                          }
                          key={`live-directory|${seedId}`}
                          subTitle={<LiveDescription text={description} />}
                          title={name}
                          url={url}
                        >
                          <NavLink
                            css={{ display: 'block', position: 'relative' }}
                            to={url}
                          >
                            <PlayButtonContainerPrimitive data-test="live-directory-tile-play-button-container">
                              <PlayButton
                                className="play"
                                deferPlay={!!url}
                                playedFrom={playedFrom}
                                seedId={seedId}
                                stationId={seedId}
                                stationType={seedType}
                              />
                            </PlayButtonContainerPrimitive>
                            <TilesImageWrapper liveTile>
                              <CatalogImage
                                alt={name}
                                aspectRatio={1}
                                height={imgWidth}
                                id={seedId}
                                src={logo || rawLogo}
                                type={seedType}
                                width={imgWidth}
                              />
                            </TilesImageWrapper>
                          </NavLink>
                        </Tile>
                      ),
                    )}
                </Tiles>
              </InfiniteScroll>
            )}
          </Section>
        </PageBody>
      </>
    );
  }
}

export default LiveDirectory;
