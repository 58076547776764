import Head from './LiveDirectoryHead';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getLiveDirectoryPath } from 'state/Live/selectors';

export default connect(
  createStructuredSelector({
    pagePath: getLiveDirectoryPath,
  }),
)(Head);
