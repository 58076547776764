import styled from '@emotion/styled';

type Props = {
  left?: boolean;
  right?: boolean;
};

const LiveSelectBox = styled('div')<Props>(
  ({ left = false, right = false }) => ({
    float: 'left',
    margin: '.75rem 0 0',
    paddingLeft: left ? '1.5rem' : 0,
    paddingRight: right ? '1.5rem' : 0,
    width: '33.33%',
  }),
);

export default LiveSelectBox;
